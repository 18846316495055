<template>
    <div style="padding: 20px;">
        <SearchCommon :formInline="formInline" :formList="formList" @onSearch="onSearch" @onResetBtn="onResetBtn"></SearchCommon>
        <template>
            <TableCommon isMargin="20px" :tableColumn="tableColumn" :tableData="tableData">
                <el-table-column
                label="状态"
                align="center">
                    <template slot-scope="scope">
                       <span>{{ scope.row.status == 0 ? '未处理' : scope.row.status == 1 ? '处理中' : '已处理'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                align="center">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-reading" size="medium" v-if="scope.row.status == 2" @click="handleCustomizaEvent('look',scope.row)">详情</el-button>
                        <el-button type="text" icon="el-icon-thumb" size="medium" v-else @click="handleCustomizaEvent('examine',scope.row)">处理</el-button>
                        <el-button type="text" icon="el-icon-delete" size="medium" @click="handleCustomizaEvent('remove', scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </TableCommon>
        </template>
        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNum"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <lookCustomiza ref="lookCustomizaDialog"></lookCustomiza>
        <examine ref="examineDialog"></examine>
    </div>
</template>
<script>
import SearchCommon from '../../../components/SearchCommon.vue';
import TableCommon from '../../../components/TableCommon.vue';
import examine from './components/examine.vue';
import lookCustomiza from './components/lookCustomiza.vue';
export default {
    data() {
        return {
            vkey: 'customizaPage',
            formInline: {
                status: '',
                pageNum: 1,
                pageSize: 10
            },
            formList: [
                { label: '状态', model: 'status', placeholder: '请选择处理状态', type: 'select', options: [
                    { value: 0, label: '未处理'}, { value: 1, label: '处理中'}, { value: 2, label: '已处理'}]
                }
            ],
            tableColumn: [
                { label: '序号', type: 'index', align: 'center', width: '60' },
                { label: '姓名', prop: 'name', align: 'center' },
                { label: '手机号', prop: 'phone', align: 'center' },
                { label: '邮箱', prop: 'email', align: 'center' },
                { label: '公司名称', prop: 'companyName', align: 'center' },
                { label: '申请时间', prop: 'applyTime', align: 'center' },
                { label: '处理人', prop: 'solvedName', align: 'center' },
                { label: '处理时间', prop: 'solvedTime', align: 'center' }
            ]
        }
    },
    components: {
        SearchCommon,
        TableCommon,
        examine,
        lookCustomiza
    },
    computed: {
        tableData(){
           return this.$store.state.manage.views[this.vkey].list || [];
        },
        total() {
            return this.$store.state.manage.views[this.vkey].paging.total;
        }
    },
    created(){
        this.onLoadList()
    },
    methods: {
        // 列表
        onLoadList(){
           this.$store.dispatch('manage/list', {
                vkey: this.vkey,
                reset: true,
                query: this.formInline,
                interfaceName: 'getCustomizaList'
           })
        },
        // 搜索
        onSearch() {
            this.formInline.pageNum = 1;
            this.onLoadList();
        },
        // 清空
        onResetBtn() {
            this.formInline = {
                status: '',
                pageNum: 1,
                pageSize: 10
            }
            this.onLoadList()
        },
        // 按钮操作
        handleCustomizaEvent(type, row) {
            switch(type) {
                case 'look':
                    this.$nextTick(()=>{
                        this.$refs.lookCustomizaDialog.show(row);
                    })
                break;

                case 'examine':
                    this.$nextTick(()=>{
                        this.$refs.examineDialog.show(row);
                    })
                break;

                case 'remove':
                    this.$confirm('确定是否要删除当前选择的数据?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('manage/remove', {
                            vkey: this.vkey,
                            query: row.id,
                            requestInterfaceName: 'removeCustomiza',
                            listInterfaceName: 'getCustomizaList'
                        }).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('删除成功');
                                this.formInline.pageNum = 1;
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }).catch(() => {});
                break;
            }
        },
        // 分页
        handleCurrentChange(val){
            this.formInline.pageNum = val;
            this.onLoadList();
        }
    }
  }
</script>
<style lang="less" scoped>
</style>