<template>
    <el-dialog
        title="处理"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
            <el-form-item label="姓名:">
                <span>{{ form.name }}</span>
            </el-form-item>
            <el-form-item label="手机号:">
                <span>{{ form.phone }}</span>
            </el-form-item>
            <el-form-item label="邮箱:">
                <span>{{ form.email }}</span>
            </el-form-item>
            <el-form-item label="公司名称:">
                <span>{{ form.companyName }}</span>
            </el-form-item>
            <el-form-item label="申请时间:">
                <span>{{ form.applyTime }}</span>
            </el-form-item>
            <el-form-item label="处理状态" prop="status">
                <el-select  v-model="form.status" placeholder="请选择处理状态">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" :rows="2" v-model="form.remark" placeholder="请输入处理备注"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible: false,
            form: {
                name: '',
                phone: '',
                email: '',
                companyName: '',
                applyTime: '',
                status: null,
                remark: ''
            },
            rules: {
                status: [
                    { required: true, message: '请选择处理状态', trigger: 'change' }
                ],
                remark: [
                    { required: true, message: '请输入处理备注', trigger: 'blur' }
                ]
            },
            options: [{
                value: 0,
                label: '未处理'
            },{
                value: 1,
                label: '处理中'
            },{
                value: 2,
                label: '已处理'
            }]
        };
    },
    methods: {
        show(row) {
            this.dialogVisible = true;
            this.$refs['form'] ? this.$refs['form'].resetFields() : '';

            this.getDetailInfo(row.id);
        },
        getDetailInfo(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'customizaDetail'
            }).then(res=> {
               if(res.code == 0) {
                    this.form = {
                        name: res.data.name,
                        phone: res.data.phone,
                        email: res.data.email,
                        companyName: res.data.companyName,
                        applyTime: res.data.applyTime,
                        id: res.data.id,
                        status: res.data.status,
                        remark: res.data.remark
                    }
               }
            })
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$store.dispatch('manage/public', {
                        query: {
                            id: this.form.id,
                            status: this.form.status,
                            remark: this.form.remark
                        },
                        requestInterfaceName: 'customizaExamine'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success('处理成功');
                            this.$parent.onLoadList();
                        } else {
                            this.$message.error(res.message);
                        }
                        this.closeDialog();
                    })
                }
            })
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
</style>