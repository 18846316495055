<template>
    <el-dialog
        title="查看详情"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" ref="form" :inline="true" label-width="80px" class="demo-ruleForm">
            <el-form-item label="姓名:">
                <span>{{ form.name }}</span>
            </el-form-item>
            <el-form-item label="手机号:">
                <span>{{ form.phone }}</span>
            </el-form-item>
            <el-form-item label="邮箱:">
                <span>{{ form.email }}</span>
            </el-form-item>
            <el-form-item label="公司名称:">
                <span>{{ form.companyName }}</span>
            </el-form-item>
            <el-form-item label="申请时间:">
                <span>{{ form.applyTime }}</span>
            </el-form-item>
            <el-form-item label="处理人:">
                <span>{{ form.solvedName }}</span>
            </el-form-item>
            <el-form-item label="处理时间:">
                <span>{{ form.solvedTime }}</span>
            </el-form-item>
            <el-form-item label="处理状态:">
                <span>{{ form.status == 0 ? '未处理' : form.status == 1 ? '处理中' : '已处理' }}</span>
            </el-form-item>
            <el-form-item label="需求描述:">
                <span>{{ form.description }}</span>
            </el-form-item>
            <el-form-item label="备注:">
                <span>{{ form.remark }}</span>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible: false,
            form: {
               name: '',
               phone: '',
               email: '',
               companyName: '',
               applyTime: '',
               solvedName: '',
               solvedTime: '',
               status: '',
               description: '',
               remark: ''
            }
        };
    },
    methods: {
        show(row) {
            this.dialogVisible = true;
            this.getDetailInfo(row.id);
        },
        getDetailInfo(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'customizaDetail'
            }).then(res=> {
                if(res.code == 0) {
                    this.form = {
                        name: res.data.name,
                        phone: res.data.phone,
                        email: res.data.email,
                        companyName: res.data.companyName,
                        applyTime: res.data.applyTime,
                        solvedName: res.data.solvedName,
                        solvedTime: res.data.solvedTime,
                        status: res.data.status,
                        description: res.data.description,
                        remark: res.data.remark
                    }
                }
            })
        },
        closeDialog() {
            this.dialogVisible = false;
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    .el-form-item {
        width: 50%;
        margin-right: 0;
    }
}
</style>